<template>
  <div id="app" :class="theme">
    <div class="wrap">
      <div class="guide-img"></div>
      <div class="download-wp">
        <img :src="basicInfo.logo_l"  alt="" class="brand">
        <div class="title">{{basicInfo.appName}}Tải APP tổng hợp</div>
        <div class="sub-title">{{basicInfo.appName}} COMPLEX APP DOWNLOAD</div>
        <div class="contents">
          Tại đây có đầy đủ tất cả các game hiện hành, với nhiều ưu đãi cực hấp dẫn, đội ngũ nhân viên kỹ thuật có mặt mọi
          lúc và công nghệ bảo mật tiên tiến, đảm bảo thông tin cá nhân và quỹ tiền trong tài khoản an toàn tuyệt đối. Tối
          ưu máy chủ độc quyền, đem lại trải nghiệm trò chơi ưu việt.
        </div>
        <div class="qr-code-wp">
          <div class="left-part">
            <div class="qr-code">
              <qrcode-vue :value="qrValue" size="140"></qrcode-vue>
            </div>
            <div class="spec">Hỗ trợ tất cả thiết bị di động hệ iOS &Android</div>
          </div>
          <div class="right-part">
            <div class="spec">Quét mã để tải về
              <br>
              {{basicInfo.appName}}APP tổng hợp</div>
            <div class="device">
              <div class="ios"></div>
              <div class="text">IOS</div>
            </div>
            <div class="device">
              <div class="android"></div>
              <div class="text">Android</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { mapState } from 'vuex'
export default {
  name: 'App',
  components: {
    QrcodeVue,
  },
  computed: {
    ...mapState(["basicInfo"])
  },
  data() {
    return {
      theme: '',
      qrValue: '',
    }
  },
  methods: {
    init() {
      this.$store.dispatch('getBasicInfo').then((res) => {
        this.$store.commit('SET_BASIC_INFO', res.data);
        this.theme = this.basicInfo.theme;
        // 创建一个新的 link 元素
        var linkElement = document.createElement('link');

        // 设置 link 元素的属性
        linkElement.rel = 'shortcut icon';
        linkElement.href = this.basicInfo.logo_s; // 替换为你的 favicon.ico 文件的路径

        // 获取文档头部
        var headElement = document.head || document.getElementsByTagName('head')[0];

        // 删除现有的 favicon，如果存在
        var existingFavicon = document.querySelector("link[rel='shortcut icon']");
        if (existingFavicon) {
          headElement.removeChild(existingFavicon);
        }

        // 将新的 link 元素添加到文档头部
        headElement.appendChild(linkElement);
      })
    }
  },
  created() {
    this.qrValue = window.location.origin;
  },
  mounted() {
    this.init();
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100vh;
}

.black {
  background: url("./assets/images/black/bg.png") ;
  background-size: cover;
  .guide-img {
    background: url("./assets/images/black/guide.png") no-repeat;
    background-size: 100%;
  }
  .download-wp {
    background: url("./assets/images/black/download-bg.png") no-repeat;
    background-size: 100%;

    .title {
      background-image: linear-gradient(to bottom, #94B3FF, #5B7CFE);
      -webkit-background-clip: text;
      color: transparent;
    }

    .sub-title {
      color: #BDD0FF;
    }

    .contents {
      color: #BDD0FF;
    }

    .qr-code-wp {
      .left-part {
        .qr-code {
          background: #333333;
        }
        .spec {
          color: #BDD0FF;
        }
      }

      .right-part {
        .spec {
          color: #BDD0FF;
        }
        .device {
          background: url("./assets/images/black/device-bg.png") right no-repeat;
          background-size: 160px 40px;
          .ios {
            background: url("./assets/images/black/apple.png") center top;
            background-size: 100%;
          }
          .android {
            background: url("./assets/images/black/android.png") center top;
            background-size: 100%;
          }
          .text {
            color: #BDD0FF;
          }
        }

      }
    }
  }
}

.white {
  background: url("./assets/images/white/bg.png");
  background-size: cover;
  .guide-img {
    background: url("./assets/images/white/guide.png") no-repeat;
    background-size: 100%;
  }

  .download-wp {
    background: url("./assets/images/white/download-bg.png") no-repeat;
    background-size: 100%;

    .title {
      background-image: linear-gradient(to bottom, #7146FF, #4A69FF);
      -webkit-background-clip: text;
      color: transparent;
    }

    .sub-title {
      color: #7785A9;
    }

    .contents {
      color: #7785A9;
    }

    .qr-code-wp {
      .left-part {
        .qr-code {
          background: #FFFFFF;
          box-shadow: 0 0 6px 0 #7785A9;
        }
        .spec {
          color: #7785A9;
        }
      }


      .right-part {
        .spec {
          color: #7785A9;
        }
        .device {
          background: url("./assets/images/white/device-bg.png") right no-repeat;
          background-size: 160px 40px;
          .ios {
            background: url("./assets/images/white/apple.png") center top;
            background-size: 100%;
          }
          .android {
            background: url("./assets/images/white/android.png") center top;
            background-size: 100%;
          }
          .text {
            color: #BDD0FF;
          }
        }

      }
    }
  }
}

.wrap {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.guide-img {
  flex-shrink: 0;
  margin-right: 20px;
  width: 956px;
  height: 795px;
}

.download-wp {
  flex-shrink: 0;
  padding: 20px 40px;
  width: 376px;
  height: 614px;

  .brand {
    display: block;
    margin: auto;
    width: 240px;
    height: 60px;
    background-size: contain;
  }

  .title {
    padding:  20px 0 12px 0;
    height: 42px;
    line-height: 42px;
    font-family: Helvetica-Bold;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
  }

  .sub-title {
    line-height: 30px;
    font-family: Helvetica;
    text-align: center;
    font-size: 22px;
  }

  .contents {
    padding: 12px 0;
    font-family: Helvetica;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
  }

  .qr-code-wp {
    display: flex;
    .left-part {
      margin-right: 12px;
      width: 160px;
      .qr-code {
        padding: 10px;
        height: 140px;
        border-radius: 10px;
      }
      .spec {
        padding-top: 12px;
        font-family: Helvetica;
        font-size: 14px;
        letter-spacing: 0.5px;
        text-align: center;
        line-height: 18px;
      }
    }

    .right-part {
      .spec {
        font-family: Helvetica-Bold;
        font-size: 20px;
        letter-spacing: 0.34px;
        text-align: center;
        line-height: 24px;
        font-weight: 700;
      }
      .device {
        display: flex;
        margin-top: 14px;
        height: 44px;
        width: 180px;

        .ios, .android {
          width: 44px;
          height: 44px;
        }

        .text {
          width: 120px;
          line-height: 44px;
          font-family: Helvetica;
          font-size: 24px;
          text-align: center;
        }
      }
    }
  }
}


</style>
