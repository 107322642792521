import Vue from 'vue'
import Vuex from 'vuex'
import { initFun } from "@/api/app";

Vue.use(Vuex)

const store = new Vuex.Store({
    state() {
        return {
            isIOS: false,
            isAndroid: false,
            basicInfo: {},
        }
    },
    mutations: {
        SET_DEVICE_ANDROID: (state, payload) => {
            state.isAndroid = payload
        },
        SET_DEVICE_IOS: (state, payload) => {
            state.isIOS = payload
        },
        SET_BASIC_INFO: (state, payload) => {
            state.basicInfo = payload;
        }
    },
    actions: {
        getBasicInfo({ commit }) {
            return new Promise((resolve, reject) => {
                initFun().then(response => {
                    commit('SET_BASIC_INFO', response.data);
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
})
export default store
